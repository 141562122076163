
<template>
  <v-card>
    <v-card-title>
      Bulk Update
    </v-card-title>
    
    <v-container>
      <p class="font-weight-medium">Instruction</p>
      <p>Upload an Excel Sheet that you would like to have the Payout marked as NOT credited. Removed all the unecessary rows and columns and rename the column header that contains <code>Payout ID</code> to <code>payoutId</code>.</p>

      <v-form ref="form">
        <v-file-input
          v-model="config.fileToUpload"
          accept=".xlsx"
          @change="selectFile"
          label="Upload an Excel File (.xlsx)"
        />

        <v-select
          v-model="uploadData.credited"
          label="Credited Status"
          :items="[ false, true ]"
        />

        <v-btn color="primary" @click="update">Update</v-btn>
      </v-form>
    </v-container>
   
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import XLSX from 'xlsx';

const service = new RestResource();
export default {
  data() {
    return {
      config: {
        fileToUpload: true,
      },
      uploadData: {
        data: [],
        credited: false,
      }
    }
  },
  methods: {
    selectFile(e) {
      if (e) {
        const file = e;

        let reader = new FileReader();
        reader.onload = () => {
          const bstr = reader.result;

          const wb = XLSX.read(bstr, {type:'binary'});

          const ws = wb.Sheets[wb.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(ws, { header:1 });        
          this.uploadData.data = this.arrayOfArrayToJson(data[0], data)
        };

        reader.readAsBinaryString(file);
      }
    },
    arrayOfArrayToJson(questions, data) {
      let overall = []

      for (let i = 1; i < data.length; i++) {
        let row = {}

        for (let j = 0; j < data[i].length; j++) {            
          if (data[i][j] != undefined) {
            row[questions[j]] = data[i][j] 
          }
        }

        overall.push(row)
      }

      return overall
    },
    update() {
      if (this.$refs.form.validate()) {
        let payoutId = []
        
        for (let row of this.uploadData.data) {
          if (row.payoutId != undefined && row.payoutId != null & row.payoutId != "") {
            payoutId.push(row.payoutId.replace("Payout ID ", ""))
          }
        }

        if (payoutId.length > 0) {
          this.$setLoader();
          service.setCheckoutStatus({ rewardCashoutIds: payoutId, credited: this.uploadData.credited }).then(res => {
            this.$showSuccessMessage(res.data)
          });
        } else {
          alert("No Payout ID found!")
        }
      }
    }
  }
};
</script>
